import cx from "classnames"
import { pushDataLayer } from "@/lib/helpers/tagManager"
import HeadingLabel from "@/common/partials/headingLabel/headingLabel"
import Button from "@/components/common/button/button"
import Arrow from "@/components/common/icons/arrow"
import styles from "./member-blurb.module.css"
export default function MemberBlurb({
  tag,
  heading,
  CTATracking = null,
  button = "normal",
  buttonStyle,
  url
}) {
  return (
    <div className={cx(styles.container, styles[buttonStyle])}>
      <HeadingLabel className="mb-2.5">{tag}</HeadingLabel>
      <div className={styles.holder}>
        <h5 className={styles.heading}>{heading}</h5>
        <Button
          className={styles.button}
          theme="green"
          href={url}
          onClick={() => {
            if (CTATracking) {
              pushDataLayer(CTATracking)
            }
          }}
          tagName="a">
          {buttonStyle === "normal" && <>{button}</>}
          {buttonStyle === "small" && <Arrow className="w-3.5" />}
        </Button>
      </div>
    </div>
  )
}
